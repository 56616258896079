.MuiPaper-root.requirement-card {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 16px;
    height: 60px;
    display: flex;
    justify-content: space-between;
    box-shadow: 0 0 1px 1px rgb(0 0 0 / 20%);
    padding-right: 10px;
    .card-content {
        width: 60%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 14px;
        font-weight: bold;
        .status{
            display: flex;
            width: 20%;
            justify-content: center;
            font-weight: 700;
            &.pending{
                color: orange
            }
            &.submitted{
                color: green;
            }
        }
    }
    @media (max-width: 767px) {
        padding-right: 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: fit-content;
        .card-content{
            width: 100%;
            font-size: 12px;
            display: flex;
            align-items: center;
            flex-direction: row;
            justify-content: flex-start;
        }
        button{
            font-size: 12px;
            margin:auto;
            padding: 4px 4pxs
        }
    }
}