.pages-principal {
    margin-bottom: 32px;
    position: relative;
    .logo {
        display: flex;
        justify-content: start;
        align-items: center;
        margin-bottom: 20px;
        padding: 8px;
        padding-left: 24px;
        padding-right: 24px;
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
        background-color: rgb(20,20,20,0.02);
        justify-content: space-between;
        .user-greeting{
            font-weight: bold;
        }
        img{
            width: 150px;
        }
    }

    .requirements {
        margin-bottom: 32px;
        width: 100%;
    }

    .footer {
        display: flex;
        justify-content: space-around;
        position: fixed;
        bottom: 0;
        max-width: 1000px;
        width: 100%;
        backdrop-filter : blur(9px);
        .wrap-box{
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            justify-content: center;
        }
        .child-box{ 
            align-items: center;
            text-align: center;
            display: flex;
            padding: 5px;
            .icon-footer{
                width: 19px;
                padding-right: 11px;
            }
        }
        .hash{
            text-decoration: none;
            font-size: 12px;
        }
    }
}