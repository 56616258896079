.pages-requirement{
    margin-bottom: 32px;
    padding-bottom: 60px;
    position: relative;
    max-width: inherit;
    width: 100%;
    .logo {
        display: flex;
        justify-content: start;
        align-items: center;
        margin-bottom: 20px;
        padding: 8px;
        padding-left: 24px;
        padding-right: 24px;
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
        background-color: rgb(250,250,250);
        justify-content: space-between;
        img{
            width: 150px;
        }
    }

    .footer {
        position: fixed;
        bottom: 0;
        display: flex;
        justify-content: start;
        align-items: center;
        padding: 8px 0px;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
        background-color: rgb(250,250,250);
        justify-content: space-around;
        width:inherit;
        max-width:inherit;
        width: inherit;
        p {
            font-size: 12px;
        }
        a {
            font-size: 12px;
        }
    }

    
}
.requirement-content {
    margin: 0px 32px;
    padding-bottom: 60px
}

@media (max-width: 767px) {
    .requirement-content{
        padding-bottom: 80px;
    }
}