.form{
    @media (max-width: 767px) {
        .MuiGrid-root{
            &.MuiGrid-container{
                display: block;
            }
        }
        .form-content{
            min-height: calc(100vh - 300px);
            box-sizing: border-box;
        }
    }
    .form-content{
        min-height: calc(100vh - 300px);
        box-sizing: border-box;
    }
    .MuiInput-underline.Mui-error:after {
        border-bottom: 0;
    }
    .buttons-wrapper{
        display: flex;
        justify-content: space-between;
        margin-top: 40px;
        .back{
            background-color: red;
            color: white;
        }
        .submit:not(.Mui-disabled){
            background-color: green;
            color: white;
        }
    }
}