.signature{
    .signature-wrapper{
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 10px;
        .signature-pad{
            border: 1px solid black;
            width: 300px;
            min-height: 150px;
            display: block;
        }
        button{
            margin-top: 10px;
        }
    }
    .buttons-wrapper{
        display: flex;
        justify-content: space-between;
        margin-top: 40px;
        .back{
            background-color: red;
            color: white;
        }
        .submit:not(.Mui-disabled){
            background-color: green;
            color: white;
        }
    }
}
.legal-text{
    text-align: justify;
}
.table{
    width: 100%;
    margin: 10px 0 80px 0;
}
.table-head{
    background-color: #c6c6c6;
    border-bottom: 1px solid black;
    padding: 5px;
}
.table-th{
    border: 1px solid black;
    padding: 5px;
}
.table-td{
    border: 1px solid black;
    padding: 5px;
}