.file-upload{
    .dropzone {
        text-align: center;
        padding: 20px;
        border: 3px dashed #eeeeee;
        background-color: #fafafa;
        color: #bdbdbd;
        margin-bottom: 20px;
    }
    .file-container{
        display: flex;
        min-height: 103px;
        flex-wrap: wrap;
    }
    .buttons-wrapper{
        display: flex;
        justify-content: space-between;
        margin-top: 20px;
        .back{
            background-color: red;
            color: white;
        }
        .submit:not(.Mui-disabled){
            background-color: green;
            color: white;
        }
    }
    @media (max-width: 767px) {
        .file-container{
            min-height: 80px;
        }
    }
}