.norequirement-card-container{
    width: 60%;
    margin: auto;
    padding-bottom: 5%;
    .norequirement-card{
        text-align: center;
        font-size: 20px;
        padding: 10px;
    }
    .norequirement-card-title{
        font-size: 25px;
        font-weight: 700;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

@media (max-width: 767px) {
    .norequirement-card-container{
        width: 90%;
    }
}