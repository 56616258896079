@import "../../colors/index.scss";

.MuiPaper-root.error-card {
    width: 50%;
    min-width: 400px;
    margin-top: 48px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 30px;
    display: flex;
    flex-direction: column;
    color: $white;
    background-color: $primary;
    
    .email-description {
        margin-top: 0px;
        margin-bottom: 16px;
        text-align: center;
        font-size: 12px;
        font-weight: bold;
        color: gray;
    }

    div.email-box {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }
    .MuiCardActions-root {
        display: flex;
        flex-direction: column;
    }
}