
.pages-loading{
    //text-align: center;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin: auto;

    .loading-label{
        margin-bottom: 20px;
        font-size: 18px;
        font-weight: bold;
        width: 25%;
        min-width: 200px;
    }
}